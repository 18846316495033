import React from "react";
import NewsBan from "../components/NewsBan";
// import NewsCard from "../components/NewsCard";
import Articles from "../components/Articles";
// import Info2 from "../components/Info2";
const News = () => {
  return (
    <div>
      <NewsBan />
      {/* <NewsCard /> */}
      <Articles />
      {/* <Info2 /> */}
    </div>
  );
};

export default News;
